import {css} from 'lit';
export const styles = css`:host {
  --card-padding: 16px;

  /* @include box-shadow-2; from @beunity/design */
  box-shadow: 0 1px 2px rgba(#000, 0.04), 0 2px 4px rgba(#32325d, 0.06);
  position: relative;
  display: block;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid var(--gray-new-100);
}

  @media (max-width: 575px) {:host {
    box-shadow:
      0 6px 10px rgba(153, 153, 153, 0.14),
      0 1px 18px rgba(153, 153, 153, 0.12),
      0 3px 5px rgba(153, 153, 153, 0.02);
    border-width: 1px
}
  }

:host(.card-no-prefooter) bu-card-prefooter {
    display: none;
  }

:host(.card-no-prefooter) .event-details {
    border-bottom: none;
    padding-bottom: 0;
  }

bu-card-header {
  padding: 16px;
}

:host([type="marketplace"]) .notice.t1 {
    margin-bottom: -32px;
    padding-bottom: 32px;
  }

:host([type="marketplace"]) [class~="notice"] ~ bu-card-header {
    background-color: var(--contrast-light);
    margin-top: 16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

:host([type="marketplace"]) [class~="notice"] ~ bu-card-preheader {
    padding-top: 16px;
  }

.event-details {
  min-height: 44px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  align-items: center;
  border-bottom: 1px solid var(--border);
  word-break: break-word;
}

.card-kind {
  position: absolute;
  top: -4px;
  right: -10px;
  padding: 0.125em 0.5em;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #fff;
  background-color: var(--secondary-500);
  font-size: 0.85em;
  transform: rotate(12deg);
  z-index: 1;
}

.notice {
  display: flex;
  color: var(--contrast-light);
  font-size: var(--bu-font-size-xs);
  padding: 16px;
  z-index: 1;
  margin-bottom: -16px;
  border-radius: 8px 8px 0 0;
  padding-bottom: 32px;
}

.notice a {
    text-decoration: underline;
    color: inherit;
  }

.notice.t1 {
    display: flex;
    background-color: #d70015;
    margin-bottom: -16px;
    border-radius: 8px 8px 0 0;
    padding-bottom: 32px;
  }

.notice.t1 bu-icon {
      margin-right: 8px;
      font-size: 20px;
    }

.notice.t2 {
    background-color: #fde7d0;
    color: var(--gray-new-800);
    padding: 8px 8px 24px;
  }

.notice.t2 bu-icon {
      color: var(--gray-new-500);
      margin-right: 8px;
    }

.toggle-content {
  --link-focus-outline-offset: -2px;

  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  background: transparent;
  border: none;
  color: var(--text-secondary);
}

.card-body {
  margin-bottom: 16px;
  padding-left: var(--card-padding);
  padding-right: var(--card-padding);
  font-size: 14px;
}

:host(.card-smart-collapsed) {
  margin-top: -17px;
  padding-top: 0;
  border-radius: 0;
  border-top: 1px solid var(--border-primary);
}

/* @include media-min(sm) from @beunity/design */

@media (min-width: 768px) {

:host(.card-smart-collapsed) {
    margin-top: -33px
}
  }

:host(.card-smart-collapsed) bu-card-header {
    padding: 8px;
    margin-bottom: 0;
    border-bottom: 0;
  }

:host(.card-smart-collapsed) bu-card-header ef-date-widget {
      --font-size-primary: 11px;
    }

:host(.card-smart-collapsed) .card-body,
  :host(.card-smart-collapsed) .card-footer {
    display: none;
  }

:host(.card-chain-next) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

bu-card-prefooter {
  margin: 24px 16px 8px;
}

slot[name="bottom"]::slotted(*) {
  border-radius: 0 0 8px 8px;
}

.attachments-link {
  margin-top: 16px;
  margin-bottom: 16px;
}
`;
export default styles;
